// config.js

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDbXYRBVQkDUqhPeFjT2s52j4Jwr-mvNeE",
    authDomain: "penzion-poukazy.firebaseapp.com",
    projectId: "penzion-poukazy",
    storageBucket: "penzion-poukazy.appspot.com",
    messagingSenderId: "473435804421",
    appId: "1:473435804421:web:51cffdce3cb4def99c9d6c"
};

// Pokud Firebase App ještě nebyla inicializována, provede inicializaci
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const projectAuth = firebase.auth();
const projectFirestore = firebase.firestore();

export { projectAuth, projectFirestore };