import React, { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import { projectAuth } from '../firebase/config';
import './CouponManagement.css'; // Importovat CSS pro CouponManagement

const CouponManagement = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        projectAuth.signOut();
        localStorage.removeItem('user');
        navigate('/');
    };

    const [coupons, setCoupons] = useState([]);
    const [newCouponCode, setNewCouponCode] = useState('');
    const [newExpiryDate, setNewExpiryDate] = useState('');
    const [newCouponDescription, setNewCouponDescription] = useState('Privátní wellness');
    const [verificationCouponCode, setVerificationCouponCode] = useState('');
    const [verificationResult, setVerificationResult] = useState('');
    const [generateMultipleCoupons, setGenerateMultipleCoupons] = useState(false);
    const [numberOfCodesToGenerate, setNumberOfCodesToGenerate] = useState(1);

    useEffect(() => {
        const unsubscribe = projectFirestore.collection('coupons').onSnapshot((snapshot) => {
            const updatedCoupons = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
    
            // Seřadí poukazy podle data expirace od nejstarších
            updatedCoupons.sort((a, b) => a.expiryDate - b.expiryDate);
    
            setCoupons(updatedCoupons);
        });
    
        return () => unsubscribe();
    }, []);


    const generateRandomCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let code = '';
        for (let i = 0; i < 5; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return code;
    };

    const handleGenerateCoupon = () => {
        let generatedCode = generateRandomCode();

        while (coupons.some(coupon => coupon.code === generatedCode)) {
            generatedCode = generateRandomCode();
        }

        setNewCouponCode(generatedCode);
    };

    const handleAddCoupon = async (e) => {
        e.preventDefault();

        // Kontrola existence kódu v seznamu
        if (coupons.some(coupon => coupon.code === newCouponCode)) {
            setVerificationResult('Tento kód již existuje.');
            return;
        }

        if (generateMultipleCoupons) {
            for (let i = 0; i < numberOfCodesToGenerate; i++) {
                const generatedCode = generateRandomCode();

                if (coupons.some(coupon => coupon.code === generatedCode)) {
                    setVerificationResult('Jeden z kódů již existuje.');
                    return;
                }

                const expiryDate = new Date(newExpiryDate);
                const currentDate = new Date();

                let status = 'platný';
                if (expiryDate < currentDate) {
                    status = 'vyexpirovaný';
                }

                try {
                    await projectFirestore.collection('coupons').add({
                        code: generatedCode,
                        expiryDate: expiryDate,
                        description: newCouponDescription,
                        status: status,
                        createdAt: new Date() // Přidáme datum vytvoření
                    });
                } catch (error) {
                    console.error('Error adding document: ', error);
                }
            }
        } else {
            const expiryDate = new Date(newExpiryDate);
            const currentDate = new Date();

            let status = 'platný';
            if (expiryDate < currentDate) {
                status = 'vyexpirovaný';
            }

            try {
                await projectFirestore.collection('coupons').add({
                    code: newCouponCode,
                    expiryDate: expiryDate,
                    description: newCouponDescription,
                    status: status,
                    createdAt: new Date() // Přidáme datum vytvoření
                });
            } catch (error) {
                console.error('Error adding document: ', error);
            }
        }

        setNewCouponCode('');
        setNewExpiryDate('');
        setNewCouponDescription('Privátní wellness');
        setVerificationResult('');
    };

    const handleRedeemCoupon = async (e) => {
        e.preventDefault();
    
        try {
            const couponRef = projectFirestore.collection('coupons').where('code', '==', verificationCouponCode);
            const snapshot = await couponRef.get();
    
            if (snapshot.empty) {
                console.log('No matching documents.');
                setVerificationResult(`Poukaz ${verificationCouponCode} neexistuje.`);
                return;
            }
    
            snapshot.forEach(async doc => {
                const couponData = doc.data();
                if (couponData.status === 'platný') {
                    await projectFirestore.collection('coupons').doc(doc.id).update({ status: 'uplatněný' });
                    console.log(`Poukaz ${verificationCouponCode} byl úspěšně uplatněn.`);
                    setVerificationResult(`Poukaz ${verificationCouponCode} byl úspěšně uplatněn. Popis: ${couponData.description}`);
    
                    const updatedCoupons = coupons.map(coupon => {
                        if (coupon.code === verificationCouponCode) {
                            return { ...coupon, status: 'uplatněný' };
                        } else {
                            return coupon;
                        }
                    });
                    setCoupons(updatedCoupons);
    
                    await projectFirestore.collection('coupons').doc(doc.id).update({ status: 'uplatněný' });
                } else {
                    console.log(`Nelze uplatnit poukaz ${verificationCouponCode}. Stav: ${couponData.status}`);
                    setVerificationResult(`Nelze uplatnit poukaz ${verificationCouponCode}. Stav: ${couponData.status}`);
                }
            });
        } catch (error) {
            console.error('Error redeeming coupon: ', error);
            setVerificationResult('Došlo k chybě při uplatňování poukazu.');
        }
    };

    const handleVerifyCoupon = async () => {
        try {
            const couponRef = projectFirestore.collection('coupons').where('code', '==', verificationCouponCode);
            const snapshot = await couponRef.get();
    
            if (snapshot.empty) {
                console.log('No matching documents.');
                setVerificationResult(`Poukaz ${verificationCouponCode} neexistuje.`);
                return;
            }
    
            snapshot.forEach(doc => {
                const couponData = doc.data();
                setVerificationResult(`Stav: ${couponData.status}, Platnost: ${couponData.expiryDate.toDate().toLocaleDateString()}, Popis: ${couponData.description}`);
            });
        } catch (error) {
            console.error('Error verifying coupon: ', error);
            setVerificationResult('Došlo k chybě při ověřování poukazu.');
        }
    };

    const handleDeleteCoupon = async (id) => {
        try {
            await projectFirestore.collection('coupons').doc(id).delete();
            console.log('Poukaz byl úspěšně smazán.');
        } catch (error) {
            console.error('Error deleting coupon: ', error);
        }
    };

    return (
        <div className="container">
            <h2>Coupon Management</h2>
            <button className="logout-button" onClick={handleLogout}>Odhlásit se</button>
    
            <h3>Vytvoření kodu</h3>
            <form onSubmit={handleAddCoupon} className="coupon-form">
                <div className="coupon-input">
                    <label>
                        Coupon Code:
                        <input type="text" value={newCouponCode} onChange={(e) => setNewCouponCode(e.target.value)} />
                    </label>
                    <button type="button" onClick={handleGenerateCoupon} className="generate-button">Generate</button>
                </div>
                <label>
                    Expiry Date:
                    <input type="date" value={newExpiryDate} onChange={(e) => setNewExpiryDate(e.target.value)} />
                </label>
                <label>
                    Description:
                    <input type="text" value={newCouponDescription} onChange={(e) => setNewCouponDescription(e.target.value)} />
                </label>
                <label>
                    Generate Multiple Coupons:
                    <input type="checkbox" checked={generateMultipleCoupons} onChange={() => setGenerateMultipleCoupons(!generateMultipleCoupons)} />
                </label>
                {generateMultipleCoupons && (
                    <label>
                        Number of Codes to Generate:
                        <input type="number" value={numberOfCodesToGenerate} onChange={(e) => setNumberOfCodesToGenerate(e.target.value)} min="1" />
                    </label>
                )}
                <button type="submit" className="add-button">Add Coupon</button>
            </form>
            <div>
                <h3>Uplatnit</h3>
                <input
                    type="text"
                    placeholder="Enter coupon code"
                    value={verificationCouponCode}
                    onChange={(e) => setVerificationCouponCode(e.target.value)}
                />
                <button onClick={handleRedeemCoupon} className="redeem-button">Uplatnit</button>
                <button onClick={handleVerifyCoupon} className="verify-button">Ověřit</button>
                {verificationResult && <p>{verificationResult}</p>}
            </div>
            <div>
                <h3>Existing Coupons</h3>
                <table className="coupon-table">
                    <thead>
                        <tr>
                            <th>Coupon Code</th>
                            <th>Expiry Date</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coupons.map(coupon => (
                            <tr key={coupon.id}>
                                <td>{coupon.code}</td>
                                <td>{coupon.expiryDate.toDate().toLocaleDateString()}</td>
                                <td>{coupon.description}</td>
                                <td>{coupon.status}</td>
                                <td>
                                    <button className="delete-button" onClick={() => handleDeleteCoupon(coupon.id)}>Smazat</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CouponManagement;
