//CouponVerification.js
import React, { useState } from 'react';
import { projectFirestore } from '../firebase/config';
import './CouponVerification.css';

const CouponVerification = () => {
    const [verificationCouponCode, setVerificationCouponCode] = useState('');
    const [verificationResult, setVerificationResult] = useState('');

    const handleRedeemCoupon = async () => {
        try {
            const couponRef = projectFirestore.collection('coupons').where('code', '==', verificationCouponCode);
            const snapshot = await couponRef.get();
    
            if (snapshot.empty) {
                console.log('No matching documents.');
                setVerificationResult(`Poukaz ${verificationCouponCode} neexistuje.`);
                return;
            }
    
            snapshot.forEach(async doc => {
                const couponData = doc.data();
                if (couponData.status === 'platný') {
                    await projectFirestore.collection('coupons').doc(doc.id).update({ status: 'uplatněný' });
                    console.log(`Poukaz ${verificationCouponCode} byl úspěšně uplatněn.`);
                    setVerificationResult(`Poukaz ${verificationCouponCode} (${couponData.description}) byl úspěšně uplatněn.`);
                } else {
                    console.log(`Nelze uplatnit poukaz ${verificationCouponCode}. Stav: ${couponData.status}`);
                    setVerificationResult(`Nelze uplatnit poukaz ${verificationCouponCode}. Stav: ${couponData.status}`);
                }
            });
        } catch (error) {
            console.error('Error redeeming coupon: ', error);
            setVerificationResult('Došlo k chybě při uplatňování poukazu.');
        }
    };

    const handleVerifyCoupon = async () => {
        try {
            const couponRef = projectFirestore.collection('coupons').where('code', '==', verificationCouponCode);
            const snapshot = await couponRef.get();
    
            if (snapshot.empty) {
                console.log('No matching documents.');
                setVerificationResult(`Poukaz ${verificationCouponCode} neexistuje.`);
                return;
            }
    
            snapshot.forEach(doc => {
                const couponData = doc.data();
                setVerificationResult(`Popis: ${couponData.description}, Stav: ${couponData.status}, Platnost: ${couponData.expiryDate.toDate().toLocaleDateString()}`);
            });
        } catch (error) {
            console.error('Error verifying coupon: ', error);
            setVerificationResult('Došlo k chybě při ověřování poukazu.');
        }
    };

    return (
        <div className="container">
            <h2>Ověření poukazů</h2>
            <div>
                <label>
                    Kod poukazu:
                    <input 
                        type="text" 
                        value={verificationCouponCode} 
                        onChange={(e) => setVerificationCouponCode(e.target.value)} 
                    />
                </label>
                <button className="redeem-button" onClick={handleRedeemCoupon}>Uplatnit</button>
                <button className="verify-button" onClick={handleVerifyCoupon}>Ověřit</button>
            </div>
            {verificationResult && <p>{verificationResult}</p>}
        </div>
    );
};

export default CouponVerification;
