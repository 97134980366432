import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Importovat CSS pro domovskou stránku

const Home = () => {
    return (
        <div className="home-container"> {/* Přiřazení třídy home-container */}
            <h1>Poukazy penzion</h1>
            <Link to="/login" className="link">Login</Link> {/* Přiřazení třídy link */}
            <Link to="/manage" className="link">Správa poukazů</Link> {/* Přiřazení třídy link */}
            <Link to="/verify-coupon" className="link">Ověřit poukaz</Link> {/* Přiřazení třídy link */}
        </div>
    );
};

export default Home;
