// V App.js

import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login'; // Import Login
import CouponManagement from './pages/CouponManagement';
import CouponVerification from './pages/CouponVerification';

const App = () => {
    return (
        <BrowserRouter>
            <div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/verify-coupon" element={<CouponVerification />} />
                    <Route path="/login" element={<Login />} /> {/* Přidání cesty pro Login */}
                    <Route
                        path="/manage"
                        element={localStorage.getItem('user') ? <CouponManagement /> : <Navigate to="/login" />}
                    />
                </Routes>
            </div>
        </BrowserRouter>
    );
};

export default App;
